export default {
    methods: {
        async loadData(data, callback) {
            this.$store.dispatch('getNewsDetail', data).then((rs)=>{
                callback && callback(rs, null)
            }).catch((error)=>{
                callback && callback(null, error)
            })
        }
    }
}