<template>
    <div id="news-detail">
        <dl class="breadcrumb">
            <dt>您當前位置:</dt>
            <dd><router-link to="/news/list">健康資訊</router-link><svg t="1599534847217" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2986" width="16" height="16"><path d="M595.712 526.112l-205.44-197.28c-8.864-10.432-7.504-25.968 3.024-34.688 10.528-8.736 26.24-7.36 35.088 3.072l205.424 197.28c8.864 10.432 7.504 25.968-3.008 34.688S604.56 536.56 595.712 526.112z" p-id="2987" fill="#646464"></path><path d="M390.256 695.68l205.44-197.28c8.864-10.432 24.56-11.808 35.088-3.088 10.528 8.736 11.872 24.272 3.024 34.688l-205.44 197.28c-8.848 10.432-24.56 11.808-35.088 3.072C382.752 721.664 381.392 706.128 390.256 695.68z" p-id="2988" fill="#646464"></path></svg></dd>
            <dd><span>文章詳情</span></dd>
        </dl>
        <div class="detail" v-if="news">
            <div class="title-box">
                <div class="row">
                    <router-link class="category" :to="`/news/list?CatID=${news.catid}`" v-text="`#${news.category}`"></router-link>
                    <h1 class="title" v-text="news.title"></h1>
                </div>
                <div class="tags" v-if="news.tag">
                    <router-link :to="`/news/list?keyword=${tag}`" class="tag" v-for="(tag, i) in news.tag.split(/\||,|，/)" :key="i" v-text="tag"></router-link>
                </div>
                <img src="../../../assets/imgs/pc/img_fenx@2x.png" alt="" class="share" @click="doShare">
            </div>
            <div class="doctor-head">
                <img src="../../../assets/imgs/pc/img_nanys@2x.png" alt="" class="icon-doctor">
                <div class="col">
                    <div class="doctor-name" v-text="news.creator || '--' "></div>
                    <div class="create-time" v-text="news.createdtime"></div>
                </div>
            </div>
            <div class="content">
                <p class="second-title" v-text="news.introduce"></p>
                <div class="article" v-html="news.content"></div>
            </div>
        </div>
        <my-share :data="shareData" v-if="share" :onResult="()=>share=false"/>
        <loading v-if="loading" />
    </div>
</template>

<script>
    import MyShare from '@/layouts/MyShare.vue'
    import Loading from '@/layouts/Loading.vue'
    import news_detail from '@/views/mixins/news_detail'
	export default {
        mixins: [news_detail],
        components: {
            Loading,
            MyShare,
        },
        data: function(){
            return {
                share: false,
                shareData: null,
                news: null,
                loading: true,
            }
        },
        methods: {
            onRefresh: function(){
                this.loading = true
                var clientId = this.$store.getters.clientId || undefined
                this.loadData({'newsid': this.$route.params.newsId, 'clientid': clientId}, (data, error)=>{
                    this.loading = false
                    this.isRefresh = false
                    if(data) {
                        this.news = data
                    } else {
                        this._toast.warning(this, error)
                    }
                })
            },
            doShare: function(){
                this.shareData = {
                    url: `${location.origin}/news/detail/${this.news.newsid}`,
                    title: this.news.title,
                    baseUrl: location.origin
                }
                this.share = true
            }
        },
        mounted: function(){ 
            var newsId = this.$route.params.newsId
            if(newsId == parseInt(newsId)) {
                this.news = JSON.parse(sessionStorage.getItem('detail'))
                this.loading = false
            } else {
                this.$router.push('/news/list')
            }
        },
        watch: {
            '$route.params.newsId': function(newsId) {
                this.loading = true
                if(newsId == parseInt(newsId)) {
                    this.news = JSON.parse(sessionStorage.getItem('detail'))
                    this.loading = false
                } else {
                    this.$router.push('/news/list')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .ant-dropdown-menu {
        height: unset;
        overflow-y: unset;
    }

    #news-detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 500px;
    }

    #news-detail .breadcrumb {
	   display: flex;
	   flex-direction: row;
	   width: 100%;
	   height: 32px;
	   box-sizing: border-box;
	   padding: 0px 16px;
	   background-color: #fff;
	   align-items: center;
	   font-size: 16px;
	   line-height: 17px;
	   font-weight: 400;
	   color: #969696;
	   user-select: none;
   }

	#news-detail .breadcrumb dt {
		margin-right: 8px;
	}

	#news-detail .breadcrumb dd {
	   color: #646464;
	   line-height: 17px;
	}

   #news-detail .breadcrumb a {
	   margin: 0px 8px;
	   text-decoration: underline;
   }

   #news-detail .breadcrumb span {
	   margin-left: 8px;
   }

    #news-detail .breadcrumb svg {
        vertical-align: bottom;
    }

    .title-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 24px 16px;
        box-sizing: border-box;
        margin-top: 7px;
        position: relative;
    }

    .title-box > .share {
        position: absolute;
        right: 16px;
        bottom: 24px;
        cursor: pointer;
    }

    .title-box > .share > img {
        width: 21px;
        height: 21px;
    }
    
    .title-box > .row > .title,
    .title-box > .row > .category {
        color: #242020;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        display: inline;
    }

    .title-box > .row > .category {
        margin-right: 8px;
        color: #FF8000;
    }

    .title-box > .tags {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
    }
    
    .title-box > .tags > .tag {
        padding: 4px 8px 5px 8px;
        background-color: #EFF7F8;
        font-size: 16px;
        font-weight: bold;
        color: #36C4D0;
        border-radius: 4px;
        margin-right: 16px;
    }

    .doctor-head {
        width: 100%;
        height: 75px;
        box-sizing: border-box;
        background-color: #fff;
        padding: 17px 16px 20px 16px;
        display: flex;
        flex-direction: row;
        border-top: 1px solid #E8E8E8;
    }

    .doctor-head > .col {
        display: flex;
        flex-direction: column;
    }

    .doctor-head > .col > .doctor-name {
        color: #242020;
        font-size: 16px;
        font-weight: bold;
        line-height: 15px;
        margin-bottom: 11px;
    }

    .doctor-head > .col > .create-time {
        font-weight: 400;
        font-size: 16px;
        line-height: 12px;
        color: #969696;
    } 

    .doctor-head > .icon-doctor {
        width: 38px;
        height: 38px;
        margin-right: 10px;
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 0px 16px;
        box-sizing: border-box;
        padding-bottom: 36px;
    }

    .content > .second-title {
        box-sizing: border-box;
        background-color: #EFF4F9;
        padding: 15px 16px;
        position: relative;
        border: 1px solid #D9DFE4;
        color: #636363;
        font-size: 16px;
        font-weight: 400;
    }

    .content > .article {
        font-weight: 400;
        font-size: 16px;
        color: #646464;
        line-height: 24px;
        margin-top: 40px;
    }

</style>
